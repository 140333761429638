import type { Schedule, ScheduleType } from '@/data/fms/schedule/types';
import { useNotification } from '@/data/notification/hooks';
import { useCallback, useState } from 'react';
import { Failure, Success, type APIState, type Result } from '../types';
import { useFMSAPI } from '../hooks';
import type { AxiosError, AxiosResponse } from 'axios';
import { projectAtom } from '@/data/auth/project/states';
import { environmentAtom } from '@/data/fms/environment/states';
import { useAtomCallback } from 'jotai/utils';

export type PostScheduleRegisterBodyTask = {
  task_type: 'move';
  destination_point: number;
  means?: 'manual' | 'auto';
  duration_sec?: number;
};

export type PostScheduleRegisterBody = {
  schedule_type: ScheduleType;
  expiration_date?: string;
  tasks: PostScheduleRegisterBodyTask[];
};

/**
 * スケジュール登録API
 */
export const usePostScheduleRegisterAPI = (): {
  state: APIState;
  postScheduleRegister: (
    vehicleId: string,
    body: PostScheduleRegisterBody,
  ) => Promise<Schedule | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifySuccess, notifyError } = useNotification();
  const fmsAPI = useFMSAPI();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      vehicleId: string,
      body: PostScheduleRegisterBody,
    ): Promise<Result<Schedule, AxiosResponse>> => {
      try {
        const res = await fmsAPI.post(
          `/${projectId}/environments/${environmentId}/vehicles/${vehicleId}/schedules`,
          body,
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const postScheduleRegister = useAtomCallback(
    useCallback(
      async (get, _, vehicleId: string, body: PostScheduleRegisterBody) => {
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: 'スケジュール登録に失敗しました',
          });
          setState('hasError');
          return null;
        }
        setState('loading');
        const res = await request(
          project.id,
          environment.environment_id,
          vehicleId,
          body,
        );
        if (!res || res.isFailure()) {
          notifyError({
            message: `スケジュール登録に失敗しました: ${
              res.value?.data?.message ?? '原因不明'
            }`,
          });
          setState('hasError');
          return null;
        }
        notifySuccess({
          message: 'スケジュール登録が完了しました',
        });
        setState('hasValue');
        return res.value;
      },
      [notifySuccess, notifyError, request],
    ),
  );

  return {
    state,
    postScheduleRegister,
  };
};
