import { useCallback, useState } from 'react';
import { Failure, Success, type APIState, type Result } from '../types';
import type { Vehicle } from '@/data/fms/vehicle/types';
import { useFMSAPI } from '../hooks';
import { useEnableGetVehicles } from '@/data/auth/scope/hooks';
import type { AxiosError, AxiosResponse } from 'axios';
import { projectAtom } from '@/data/auth/project/states';
import { environmentAtom } from '@/data/fms/environment/states';
import { useNotification } from '@/data/notification/hooks';
import { overrideTelemetryEmergencyStatus } from '@/data/fms/vehicle/utils';
import { useAtomCallback } from 'jotai/utils';

/**
 * 走行環境内の車両一覧取得API
 */
export const useVehiclesAPI = (): {
  state: APIState;
  getVehicles: () => Promise<Vehicle[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const fmsAPI = useFMSAPI();
  const enableGetVehicles = useEnableGetVehicles();
  const { notifyError } = useNotification();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
    ): Promise<Result<Vehicle[], AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/vehicles`,
        );
        if (!res?.data) return new Success([]);
        // 昇順ソート
        res?.data?.sort((a: Vehicle, b: Vehicle) => {
          if (a.vehicle_name < b.vehicle_name) return -1;
          return 1;
        });
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getVehicles = useAtomCallback(
    useCallback(
      async (get) => {
        if (!enableGetVehicles) return [];
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: `車両一覧の取得に失敗しました`,
          });
          setState('hasError');
          return [];
        }
        setState('loading');
        const res = await request(project.id, environment.environment_id);
        if (!res || res.isFailure()) {
          setState('hasError');
          return [];
        }
        setState('hasValue');
        return res.value;
      },
      [request, enableGetVehicles, notifyError],
    ),
  );

  return {
    state,
    getVehicles,
  };
};

/**
 * 指定車両の情報取得API
 */
export const useVehicleAPI = (): {
  state: APIState;
  getVehicle: (vehicleId: string) => Promise<Vehicle | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const enableGetVehicles = useEnableGetVehicles();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      vehicleId: string,
    ): Promise<Result<Vehicle | null, AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/vehicles/${vehicleId}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getVehicle = useAtomCallback(
    useCallback(
      async (get, _, vehicleId: string) => {
        setState('loading');
        if (!enableGetVehicles) {
          setState('hasError');
          return null;
        }
        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: `車両情報の取得に失敗しました`,
          });
          setState('hasError');
          return null;
        }
        const res = await request(
          project!.id,
          environment!.environment_id,
          vehicleId,
        );
        if (!res.value || res.isFailure()) {
          notifyError({
            message: `車両一覧の取得に失敗しました`,
          });
          setState('hasError');
          return null;
        }
        if (res?.value.telemetry.status === 'shutdown') {
          overrideTelemetryEmergencyStatus(res.value.telemetry);
        }
        setState('hasValue');
        return res.value;
      },
      [notifyError, request, enableGetVehicles],
    ),
  );

  return {
    state,
    getVehicle,
  };
};
