import type valueof from '@/utils/valueof';
import type { ScheduleSetting, Settings } from './types';
import { settingAtom } from './states';
import { useCallback } from 'react';
import store from 'store2';
import { DEFAULT_SETTINGS, KEY_NAME } from './constants';
import { useMount } from 'react-use';
import { useAtom } from 'jotai';

export const useSettings = (): {
  settings: Settings;
  updateSchedule: (
    key: keyof ScheduleSetting,
    value: valueof<ScheduleSetting>,
  ) => void;
} => {
  const [settings, setSettings] = useAtom(settingAtom);

  const update = useCallback(
    (key: keyof Settings, value: valueof<Settings>) => {
      const saveSetting = { ...settings, [key]: value };
      store.local.set(KEY_NAME, saveSetting);
      setSettings(saveSetting);
    },
    [setSettings, settings],
  );

  const updateSchedule = useCallback(
    (key: keyof ScheduleSetting, value: valueof<ScheduleSetting>) => {
      const saveSchedule = {
        ...settings.schedule,
        [key]: value,
      } as ScheduleSetting;
      update('schedule', { ...settings.schedule, ...saveSchedule });
    },
    [update, settings.schedule],
  );

  useMount(() => {
    if (!store.local.get(KEY_NAME)) {
      store.local.set(KEY_NAME, DEFAULT_SETTINGS);
    }
  });

  return {
    settings,
    updateSchedule,
  };
};
