import { useVehicleAPI } from '@/api/fms';
import { useWebSocket } from '@/api/fms/websocketService';
import { selectedVehicleIdAtom, vehiclesAtom } from './states';
import type {
  ParamsType,
  Vehicle,
  VehicleIndividualParameter,
  VehicleMetadata,
} from './types';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMount, usePrevious, useUpdateEffect } from 'react-use';
import store from 'store2';
import { useAtom, useSetAtom } from 'jotai';
import { useAtomCallback } from 'jotai/utils';

export const useVehiclesDataWebSocket = () => {
  const {
    createSocket: createVehiclesTelemetrySocket,
    data: updatedVehiclesTelemetry,
    closeSocket: closeVehiclesTelemetrySocket,
  } = useWebSocket({ channel: 'environmentVehiclesTelemetry' });
  const {
    createSocket: createVehiclesMetadataSocket,
    data: updatedVehiclesMetadata,
    closeSocket: closeVehiclesMetadataSocket,
  } = useWebSocket({ channel: 'environmentVehiclesMetadataUpdates' });
  const setVehicles = useSetAtom(vehiclesAtom);
  const { getVehicle } = useVehicleAPI();

  /**
   * 作成された車両を一覧に追加
   */
  const addCreatedVehicle = useAtomCallback(
    useCallback(
      async (get, _, updatedData: VehicleMetadata) => {
        const vehicles = get(vehiclesAtom);
        const findedVehicle = vehicles.find(
          (vehicle) => vehicle.vehicle_id === updatedData.vehicle_id,
        );
        // すでに一覧に作成済み車両がある場合は中断
        if (findedVehicle) return;
        // ない場合は車両情報を取得
        const res = await getVehicle(updatedData.vehicle_id);
        if (!res) return;
        setVehicles((prevState) =>
          [...prevState, res].sort((a: Vehicle, b: Vehicle) =>
            a.vehicle_name < b.vehicle_name ? -1 : 1,
          ),
        );
      },
      [getVehicle, setVehicles],
    ),
  );

  /**
   * 削除された車両を一覧から取り除く
   */
  const removeDeletedVehicle = useAtomCallback(
    useCallback(
      async (get, _, updatedData: VehicleMetadata) => {
        const vehicles = get(vehiclesAtom);
        const findedVehicle = vehicles.find(
          (vehicle) => vehicle.vehicle_id === updatedData.vehicle_id,
        );
        // すでに一覧に削除済み車両がない場合は中断
        if (!findedVehicle) return;
        // ある場合は一覧から削除
        setVehicles((prevState) =>
          prevState.filter(
            (vehicle) => vehicle.vehicle_id !== updatedData.vehicle_id,
          ),
        );
      },
      [setVehicles],
    ),
  );

  /**
   * 更新された VehicleMetaData を既存のデータにマージして返す
   */
  const getMergedVehicleMetadata = useCallback(
    (vehicle: Vehicle, updatedData: VehicleMetadata): Vehicle => {
      if (!vehicle.calibration_parameter) {
        // 更新情報に calibration_parameter が無い場合
        return {
          ...vehicle,
          ...updatedData,
        };
      }
      // 更新情報に calibration_parameter がある場合
      const newParameterCadidateStatuses =
        vehicle.calibration_parameter.new_parameter_candidate_statuses;

      const getMergedCalibParam = () => {
        const calibParam: VehicleIndividualParameter = {
          ...vehicle.calibration_parameter,
          ...updatedData.calibration_parameter,
        };
        if (!newParameterCadidateStatuses) {
          return calibParam;
        }
        if (!newParameterCadidateStatuses.accel_brake_map) {
          // 更新情報に calibration_parameter.new_parameter_candidate_statuses がある場合
          return {
            ...calibParam,
            new_parameter_candidate_statuses: {
              ...vehicle.calibration_parameter.new_parameter_candidate_statuses,
              ...newParameterCadidateStatuses,
            },
          };
        }
        // 更新情報に calibration_parameter.new_parameter_candidate_statuses.accel_brake_map がある場合
        return {
          ...calibParam,
          new_parameter_candidate_statuses: {
            ...calibParam.new_parameter_candidate_statuses,
            accel_brake_map: {
              ...vehicle.calibration_parameter.new_parameter_candidate_statuses
                .accel_brake_map,
              ...newParameterCadidateStatuses.accel_brake_map,
            },
          },
        };
      };
      return {
        ...vehicle,
        ...updatedData,
        calibration_parameter: getMergedCalibParam(),
      };
    },
    [],
  );

  /**
   * Telemetry 更新時の処理
   */
  useEffect(() => {
    if (!updatedVehiclesTelemetry) return;
    setVehicles((prevState) => {
      return prevState.map((vehicle) =>
        vehicle.vehicle_id === updatedVehiclesTelemetry.vehicle_id!
          ? {
              ...vehicle,
              telemetry: {
                ...vehicle.telemetry,
                ...updatedVehiclesTelemetry,
              },
            }
          : vehicle,
      );
    });
  }, [updatedVehiclesTelemetry, setVehicles]);

  /**
   * Metadata 更新時の処理
   */
  useEffect(() => {
    if (!updatedVehiclesMetadata) return;
    const updatesType = updatedVehiclesMetadata.updates_type;
    const updatedData = updatedVehiclesMetadata.vehicle_data;
    if (updatesType === 'CREATED') {
      // 作成時
      addCreatedVehicle(updatedData);
      return;
    }
    if (updatesType === 'DELETED') {
      // 削除時
      removeDeletedVehicle(updatedData);
      return;
    }
    // 更新時
    setVehicles((prevState) =>
      prevState.map((vehicle) =>
        vehicle.vehicle_id === updatedData.vehicle_id
          ? getMergedVehicleMetadata(vehicle, updatedData)
          : vehicle,
      ),
    );
  }, [
    updatedVehiclesMetadata,
    getMergedVehicleMetadata,
    setVehicles,
    addCreatedVehicle,
    removeDeletedVehicle,
  ]);

  return {
    createVehiclesTelemetrySocket,
    closeVehiclesTelemetrySocket,
    createVehiclesMetadataSocket,
    closeVehiclesMetadataSocket,
  };
};

export const useSelectedVehicleId = (): {
  params: ParamsType;
  selectedVehicleId: string | null | undefined;
  setSelectedVehicleId: (vehicleId: string) => void;
  prevSelectedVehicleId: string | null | undefined;
} => {
  const params = useParams<ParamsType>();
  const [selectedVehicleId, setVehicleId] = useAtom(selectedVehicleIdAtom);
  const prevSelectedVehicleId = usePrevious(selectedVehicleId);

  /**
   * 選択中の Vehicle ID をセット
   * @param {String} vehicleId
   */
  const setSelectedVehicleId = useCallback(
    (vehicleId: string) => {
      if (!vehicleId) {
        // 存在しない場合
        setVehicleId(null);
        store.local.remove('vehicle_id');
        return;
      }
      setVehicleId(vehicleId);
      // all 以外の場合はLocalStorageに保存
      if (vehicleId !== 'all') store.local.set('vehicle_id', vehicleId);
    },
    [setVehicleId],
  );

  useMount(() => {
    if (params.vehicle_id) {
      setSelectedVehicleId(params.vehicle_id);
    }
  });

  /**
   * パスパラメータの vehicle_id が変更された場合
   */
  useUpdateEffect(() => {
    if (params.vehicle_id) {
      setSelectedVehicleId(params.vehicle_id);
    }
  }, [params.vehicle_id, setSelectedVehicleId]);

  return {
    params,
    selectedVehicleId,
    setSelectedVehicleId,
    prevSelectedVehicleId,
  };
};
