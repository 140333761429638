import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  type DialogProps as MuiDialogProps,
  DialogTitle,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMount } from 'react-use';
import store from 'store2';
import { Swiper, SwiperSlide } from 'swiper/react';
import type SwiperCore from 'swiper';
import { useGetEnvironmentsAPI } from '@/api/fms';
import { useGetProjectsAPI } from '@/api/auth';
import { usePostAssertAPI } from '@/api/auth/postAssert';
import { useRef } from 'react';
import type { Project } from '@/data/auth/project/types';
import { projectAtom, projectsAtom } from '@/data/auth/project/states';
import ProjectEnvironmentListItem from '../ProjectEnvironmentListItem';
import type { Environment } from '@/data/fms/environment/types';
import {
  environmentAtom,
  environmentsAtom,
} from '@/data/fms/environment/states';
import ProjectEnvironmentList from '../ProjectEnvironmentList';
import { useAtom, useSetAtom } from 'jotai';

type ProjectListProps = Partial<{
  selected: Project | null;
  onSelect: (project: Project) => void;
}>;

const ProjectList = ({ selected, onSelect }: ProjectListProps) => {
  const { state, getProjects } = useGetProjectsAPI();
  const { postAssertProjects } = usePostAssertAPI();
  const [projects, setProjects] = useAtom(projectsAtom);

  useMount(async () => {
    const projectsRes = await getProjects();
    const assertRes = await postAssertProjects(projectsRes);
    setProjects(assertRes);
  });

  const handleClickProject = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (onSelect) {
        const index = Number(e.currentTarget.dataset.no);
        onSelect(projects[index]);
      }
    },
    [onSelect, projects],
  );

  if (state !== 'hasValue') {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (projects.length === 0) {
    return (
      <Typography style={{ marginTop: 16 }} align="center">
        プロジェクトがありません
      </Typography>
    );
  }

  return (
    <>
      {projects.map((project, i) => (
        <ProjectEnvironmentListItem
          key={i}
          value={project.id}
          data-no={i}
          onClick={handleClickProject}
          selected={selected?.id === project.id}
        />
      ))}
    </>
  );
};

type EnvironmentListProps = Partial<{
  selected: Environment | null;
  project: Project | null;
  onSelect: (environment: Environment) => void;
}>;

const EnvironmentList = ({
  project,
  selected,
  onSelect,
}: EnvironmentListProps) => {
  const { state, getEnvironments } = useGetEnvironmentsAPI();
  const [environments, setEnvironments] = useAtom(environmentsAtom);

  const handleClickEnvironment = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const index = Number(e.currentTarget.dataset.no);
      if (onSelect) {
        onSelect(environments[index]);
      }
    },
    [environments, onSelect],
  );

  useEffect(() => {
    const updateEnvironments = async () => {
      if (!project) return;
      const res = await getEnvironments(project.id);
      setEnvironments(res);
    };
    updateEnvironments();
  }, [project, getEnvironments, setEnvironments]);

  if (!project?.id) return null;

  if (state === 'loading') {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (environments.length === 0) {
    return (
      <Typography style={{ marginTop: 16 }} align="center">
        走行環境がありません
      </Typography>
    );
  }

  return (
    <>
      {environments.map((environment, i) => (
        <ProjectEnvironmentListItem
          value={environment.environment_name}
          key={i}
          data-no={i}
          onClick={handleClickEnvironment}
          selected={selected?.environment_id === environment.environment_id}
        />
      ))}
    </>
  );
};

type TempState = {
  project: Project | null;
  environment: Environment | null;
};

type ProjectEnvironmentDialogProps = MuiDialogProps &
  Partial<{
    project: Project | null;
    environment: Environment | null;
    onClose: () => void;
    onInitialSelected: () => void;
  }>;

const ProjectEnvironmentDialog: React.FC<ProjectEnvironmentDialogProps> = ({
  project = null,
  environment = null,
  onClose,
  onInitialSelected,
  ...rest
}: ProjectEnvironmentDialogProps) => {
  const setProject = useSetAtom(projectAtom);
  const setEnvironment = useSetAtom(environmentAtom);
  const [tempSelect, setTempSelect] = useState<TempState>({
    project,
    environment,
  });
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();
  const swiperRef = useRef<SwiperCore>();

  useEffect(() => {
    setTempSelect({
      project,
      environment,
    });
  }, [project, environment]);

  const handleChangeTab = useCallback((_: unknown, newValue: number) => {
    setTabIndex(newValue);
  }, []);

  const handleChangeSlide = useCallback((swiper: SwiperCore) => {
    setTabIndex(swiper.activeIndex);
  }, []);

  const agreeDisabled = useMemo(() => {
    if (!tempSelect.project || !tempSelect.environment) return true;
    return tempSelect.project.id !== tempSelect.environment.project_id;
  }, [tempSelect]);

  const handleSelectProject = useCallback((project: Project) => {
    setTabIndex(1);
    setTempSelect((prevState) => ({
      ...prevState,
      project,
    }));
  }, []);

  const handleSelectEnvironment = useCallback((environment: Environment) => {
    setTempSelect((prevState) => ({
      ...prevState,
      environment,
    }));
  }, []);

  const handleClickCancel = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  const handleClickOk = useCallback(() => {
    setProject(tempSelect.project);
    setEnvironment(tempSelect.environment);
    store.local.set('environment', tempSelect);
    if (onClose) onClose();
    if (onInitialSelected) onInitialSelected();
  }, [onClose, onInitialSelected, setProject, setEnvironment, tempSelect]);

  useEffect(() => {
    if (!swiperRef.current) return;
    if (tempSelect.project) {
      swiperRef.current.enable();
      return;
    }
    swiperRef.current.disable();
  }, [tempSelect.project]);

  useEffect(() => {
    setTimeout(() => {
      if (!swiperRef.current) return;
      swiperRef.current.slideTo(tabIndex);
    }, 0);
  }, [tabIndex]);

  return (
    <Dialog maxWidth={false} onClose={handleClickCancel} fullScreen {...rest}>
      <DialogTitle>プロジェクトと走行環境の選択</DialogTitle>
      <DialogContent style={{ paddingBottom: 10 }}>
        <Tabs
          centered
          variant="fullWidth"
          value={tabIndex}
          onChange={handleChangeTab}
          indicatorColor="primary"
        >
          <Tab label="プロジェクト" />
          <Tab label="走行環境" disabled={!tempSelect.project} />
        </Tabs>
        <Box mt={2}>
          <Swiper
            enabled={!!tempSelect.project}
            onRealIndexChange={handleChangeSlide}
            onInit={(core) => {
              swiperRef.current = core;
            }}
            style={{
              WebkitOverflowScrolling: 'touch',
              height: window.innerHeight - 190,
            }}
          >
            <SwiperSlide style={{ height: '100%' }}>
              <ProjectEnvironmentList dir={theme.direction}>
                <ProjectList
                  selected={tempSelect.project}
                  onSelect={handleSelectProject}
                />
              </ProjectEnvironmentList>
            </SwiperSlide>
            <SwiperSlide style={{ height: '100%' }}>
              <ProjectEnvironmentList dir={theme.direction}>
                <EnvironmentList
                  selected={tempSelect.environment}
                  project={tempSelect.project}
                  onSelect={handleSelectEnvironment}
                />
              </ProjectEnvironmentList>
            </SwiperSlide>
          </Swiper>
        </Box>
      </DialogContent>
      <DialogActions>
        {!onInitialSelected && (
          <Button onClick={handleClickCancel} color="primary">
            キャンセル
          </Button>
        )}
        <Button
          onClick={handleClickOk}
          disabled={agreeDisabled}
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ProjectEnvironmentDialog);
