import {
  Box,
  Button,
  Chip,
  Grid2,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DriveEta } from '@mui/icons-material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { scheduleDataAtom } from '../Layout';
import { useHasScope } from '@/data/auth/scope/hooks';
import { useRootPath } from '@/data/fms/environment/hooks';
import type { Place } from '@/data/fms/place/types';
import { SCOPES } from '@/data/auth/scope/constants';
import { SCHEDULE_INFO_HEIGHT } from '../Layout/constants';
import { useAtomValue } from 'jotai';

type Props = {
  onClickRegister: () => void;
};

const ScheduleInfo: React.FC<Props> = ({ onClickRegister }: Props) => {
  const scheduleData = useAtomValue(scheduleDataAtom);
  const [isSamePlace, setIsSamePlace] = useState(false);
  const getHasScope = useHasScope();
  const theme = useTheme();
  const rootPath = useRootPath();

  const isRegisterable = useMemo(
    () =>
      !isSamePlace &&
      scheduleData.vehicle &&
      scheduleData.pickup &&
      scheduleData.dropoff,
    [scheduleData, isSamePlace],
  );

  const getPlaceLabel = useCallback(
    (place: Place | null) => (!place ? '未設定' : place.name),
    [],
  );

  const isTooltipOpen = useMemo(() => {
    return isSamePlace || !scheduleData.vehicle;
  }, [isSamePlace, scheduleData]);

  const tooltipMessage = useMemo(() => {
    if (!scheduleData.vehicle) {
      return (
        <span style={{ pointerEvents: 'auto', userSelect: 'none' }}>
          車両が選択されていません。
          <Link
            to={`${rootPath}settings/`}
            style={{
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            設定ページ
          </Link>
          で選択してください。
        </span>
      );
    }
    if (isSamePlace) {
      return '同じ停車地点が設定されています';
    }
    return '';
  }, [scheduleData, isSamePlace, rootPath]);

  useEffect(() => {
    if (!scheduleData.pickup || !scheduleData.dropoff) {
      setIsSamePlace(false);
      return;
    }
    setIsSamePlace(
      scheduleData.pickup.point_id === scheduleData.dropoff.point_id,
    );
  }, [scheduleData]);

  if (!getHasScope(SCOPES.CreateBasicSchedule)) {
    return null;
  }

  return (
    <WrapperBox p={2} height={SCHEDULE_INFO_HEIGHT}>
      <Tooltip
        placement="top"
        title={tooltipMessage}
        arrow
        open={isTooltipOpen}
        PopperProps={{
          style: {
            pointerEvents: 'none',
            zIndex: 1200,
          },
        }}
        slotProps={{
          tooltip: {
            sx: {
              backgroundColor: theme.palette.error.main,
              minWidth: 200,
              textAlign: 'center',
              fontSize: '0.62rem',
            },
          },
          arrow: {
            sx: {
              color: theme.palette.error.main,
            },
          },
        }}
      >
        <Grid2 container alignItems="center" justifyContent="space-between">
          <Grid2>
            <PlaceGrid container alignItems="center">
              <Grid2>
                <DriveEta color="primary" style={{ display: 'block' }} />
              </Grid2>
              <Grid2>
                <Typography variant="subtitle2">
                  {!scheduleData.vehicle
                    ? '未設定'
                    : scheduleData.vehicle.vehicle_name}
                </Typography>
              </Grid2>
            </PlaceGrid>
            <PlaceGrid container alignItems="center">
              <Grid2>
                <Chip label="乗車地" size="small" color="primary" />
              </Grid2>
              <Grid2>
                <Typography variant="subtitle2">
                  {getPlaceLabel(scheduleData.pickup)}
                </Typography>
              </Grid2>
            </PlaceGrid>
            <PlaceGrid container alignItems="center">
              <Grid2>
                <Chip label="降車地" size="small" color="primary" />
              </Grid2>
              <Grid2>
                <Typography variant="subtitle2">
                  {getPlaceLabel(scheduleData.dropoff)}
                </Typography>
              </Grid2>
            </PlaceGrid>
          </Grid2>
          <Grid2>
            <Button
              variant="contained"
              color="info"
              disabled={!isRegisterable}
              onClick={onClickRegister}
            >
              登録
            </Button>
          </Grid2>
        </Grid2>
      </Tooltip>
    </WrapperBox>
  );
};

export default ScheduleInfo;

const WrapperBox = styled(Box)`
  width: 100%;
`;

const PlaceGrid = styled(Grid2)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};

  &:last-of-type {
    margin-bottom: 0;
  }

  & > div {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;
