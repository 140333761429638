import { assertAtom } from './states';
import { useCallback, useMemo } from 'react';
import { SCOPES } from './constants';
import { projectAtom } from '../project/states';
import { environmentAtom } from '@/data/fms/environment/states';
import { useAtomValue } from 'jotai';

type FilterType = 'and' | 'or';

export const useHasFeatureScope = (): ((
  targetScope: string | string[],
  filter?: FilterType,
) => boolean) => {
  const { featureScope } = useAtomValue(assertAtom);

  const getHasFeatureScope = useCallback(
    (targetScope: string | string[], filter: FilterType = 'and') => {
      // 引数で渡されたscopeを保持しているか
      if (Array.isArray(targetScope)) {
        // 配列の場合
        if (filter === 'and') {
          // 全て含むかどうか
          return targetScope.every((s) => featureScope.indexOf(s) >= 0);
        }
        // どれかひとつでも含むかどうか
        return targetScope.some((s) => featureScope.indexOf(s) >= 0);
      }

      // 文字列の場合
      return featureScope.includes(targetScope);
    },
    [featureScope],
  );

  return getHasFeatureScope;
};

export const useHasScope = (): ((
  targetScope: string | string[],
  filter?: FilterType,
) => boolean) => {
  const project = useAtomValue(projectAtom);
  const environment = useAtomValue(environmentAtom);
  const { scope } = useAtomValue(assertAtom);

  const scopes = useMemo(() => scope.split(' '), [scope]);

  const getHasScope = useCallback(
    (targetScope: string | string[], filter: FilterType = 'and') => {
      if (!project || !environment) {
        // プロジェクトや走行環境が存在しない場合
        return false;
      }
      const prefix = `${project.id}:environment:${environment.environment_id}=`;
      // 引数で渡されたscopeを保持しているか
      if (Array.isArray(targetScope)) {
        // 配列の場合
        if (filter === 'and') {
          // 全て含むかどうか
          return targetScope.every((s) => scopes.includes(`${prefix}${s}`));
        }
        // どれかひとつでも含むかどうか
        return targetScope.some((s) => scopes.includes(`${prefix}${s}`));
      }

      // 文字列の場合
      return scopes.includes(`${prefix}${targetScope}`);
    },
    [project, environment, scopes],
  );

  return getHasScope;
};

/**
 * 車両情報（Metadata, Telemetry）を取得できる権限があるかどうか
 */
export const useEnableGetVehicles = (): boolean => {
  const getHasScope = useHasScope();
  const enable = useMemo(
    () =>
      getHasScope([
        SCOPES.DescribeVehicleMetadata,
        SCOPES.DescribeVehicleTelemetry,
      ]),
    [getHasScope],
  );
  return enable;
};
