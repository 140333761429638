import { navigationRoutes } from '@/routes';
import { enableGA } from '@/utils/googleanalytics';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMount, usePrevious } from 'react-use';
import styled from '@emotion/styled';
import ReactGA from 'react-ga4';
import { css } from '@emotion/react';
import { connectedVehiclesSelector } from '@/data/fms/vehicle/states';
import { useSettings } from '@/data/settings/hooks';
import {
  usePostScheduleRegisterAPI,
  type PostScheduleRegisterBody,
  type PostScheduleRegisterBodyTask,
} from '@/api/fms';
import { projectAtom } from '@/data/auth/project/states';
import { environmentAtom } from '@/data/fms/environment/states';
import { placesAtom } from '@/data/fms/place/states';
import { useHasScope } from '@/data/auth/scope/hooks';
import { useRootPath } from '@/data/fms/environment/hooks';
import { SCOPES } from '@/data/auth/scope/constants';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Icon,
  Radio,
  RadioGroup,
  Typography,
  type BoxProps,
} from '@mui/material';
import ScheduleInfo from '../ScheduleInfo';
import ProjectEnvironmentDialog from '../ProjectEnvrionmentDialog/ProjectEnvironmentDialog';
import DialogProgress from '../DialogProgress';
import { BOTTOM_NAVIGATION_HEIGHT, SCHEDULE_INFO_HEIGHT } from './constants';
import type { ScheduleData, SelectType } from './types';
import { atom, useAtom, useAtomValue } from 'jotai';

export type DialogSetting = {
  open: boolean;
  progress: boolean;
  type: 'place' | 'schedule';
};

export const dialogSettingAtom = atom<DialogSetting>({
  open: false,
  progress: false,
  type: 'place',
});

export const scheduleDataAtom = atom<ScheduleData>({
  selectType: 'pickup',
  tempSelectedType: 'pickup',
  tempSelectedPlace: null,
  vehicle: null,
  pickupDriveMode: 'auto',
  pickup: null,
  dropoff: null,
});

export const environmentDialogOpenAtom = atom(false);

type Props = {
  children?: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentNavigation, setCurrentNavigation] = useState(location.pathname);
  const [scheduleData, setScheduleData] = useAtom(scheduleDataAtom);
  const [dialogSetting, setDialogSetting] = useAtom(dialogSettingAtom);
  const connectedVehicles = useAtomValue(connectedVehiclesSelector(false));
  const { settings, updateSchedule } = useSettings();
  const { postScheduleRegister } = usePostScheduleRegisterAPI();
  const project = useAtomValue(projectAtom);
  const environment = useAtomValue(environmentAtom);
  const prevEnvironment = usePrevious(environment);
  const [environmentDialogOpen, setEnvironmentDialogOpen] = useAtom(
    environmentDialogOpenAtom,
  );
  const places = useAtomValue(placesAtom);
  const getHasScope = useHasScope();
  const rootPath = useRootPath();

  const hasCreateBasicScheduleScope = useMemo(
    () => getHasScope(SCOPES.CreateBasicSchedule),
    [getHasScope],
  );

  const handleChangeBottomNavigation = useCallback(
    (_: unknown, newValue: string) => {
      navigate(newValue);
    },
    [navigate],
  );

  const handleChangeType = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setScheduleData((prevState) => ({
        ...prevState,
        tempSelectedType: e.target.value as SelectType,
      }));
    },
    [setScheduleData],
  );

  /**
   *
   */
  const handleClickSettingPage = useCallback(() => {
    navigate('/settings/');
  }, [navigate]);

  /**
   *
   */
  const handleCloseEnvironmentDialog = useCallback(() => {
    setEnvironmentDialogOpen(false);
  }, [setEnvironmentDialogOpen]);

  /**
   * 登録ボタンクリック
   */
  const handleClickScheduleRegister = useCallback(() => {
    setDialogSetting({
      open: true,
      progress: false,
      type: 'schedule',
    });
  }, [setDialogSetting]);

  /**
   * ダイアログOKボタンクリック
   */
  const handleClickDialogOk = useCallback(async () => {
    if (dialogSetting.type === 'place') {
      // 乗降車地設定
      setScheduleData((prevState) => ({
        ...prevState,
        [prevState.tempSelectedType]: prevState.tempSelectedPlace,
      }));
    }
    if (dialogSetting.type === 'schedule') {
      if (
        !scheduleData.dropoff ||
        !scheduleData.pickup ||
        !scheduleData.vehicle
      )
        return;

      setDialogSetting((prevState) => ({
        ...prevState,
        progress: true,
      }));

      // スケジュール登録API リクエスト
      const tasks: PostScheduleRegisterBodyTask[] = [
        {
          task_type: 'move',
          destination_point: scheduleData.dropoff.point_id,
        },
      ];

      if (scheduleData.pickupDriveMode === 'auto') {
        // 自動運転の場合
        tasks.unshift({
          task_type: 'move',
          destination_point: scheduleData.pickup.point_id,
        });
      } else {
        // 手動運転の場合
        tasks.unshift({
          task_type: 'move',
          destination_point: scheduleData.pickup.point_id,
          means: 'manual',
          duration_sec: 30,
        });
      }

      const postBody: PostScheduleRegisterBody = {
        schedule_type: 'basic',
        tasks,
      };
      const res = await postScheduleRegister(
        scheduleData.vehicle.vehicle_id,
        postBody,
      );
      if (res) {
        if (enableGA) {
          ReactGA.event({
            category: 'click',
            action: 'schedule_register',
            label: scheduleData.vehicle.vehicle_id,
          });
        }
      }
    }
    setDialogSetting((prevState) => ({
      ...prevState,
      progress: false,
      open: false,
    }));
  }, [
    setScheduleData,
    setDialogSetting,
    dialogSetting.type,
    postScheduleRegister,
    scheduleData,
  ]);

  /**
   * ダイアログクローズ時
   */
  const handleCloseDialog = useCallback(() => {
    if (dialogSetting.progress) return;
    setDialogSetting((prevState) => ({
      ...prevState,
      open: false,
    }));
  }, [setDialogSetting, dialogSetting]);

  /**
   * ダイアログクローズ後
   */
  const handleExitedDialog = useCallback(() => {
    setScheduleData((prevState) => ({
      ...prevState,
      tempSelectedPlace: null,
    }));
  }, [setScheduleData]);

  /**
   * ダイアログタイトル
   */
  const dialogTitle = useMemo(() => {
    if (dialogSetting.type === 'place') return '乗降車地設定';
    return '配車';
  }, [dialogSetting.type]);

  /**
   * ダイアログコンテンツ
   */
  const dialogContent = useMemo(() => {
    if (dialogSetting.type === 'place' && scheduleData.tempSelectedPlace) {
      return (
        <>
          <Typography>
            <Typography variant="h6" component="span" sx={{ marginRight: 4 }}>
              <b>{scheduleData.tempSelectedPlace.name}</b>
            </Typography>
            を
          </Typography>
          <Box my={1}>
            <FormControl>
              <RadioGroup
                value={scheduleData.tempSelectedType}
                onChange={handleChangeType}
              >
                <FormControlLabel
                  value="pickup"
                  control={<Radio color="primary" />}
                  label="乗車地"
                />
                <FormControlLabel
                  value="dropoff"
                  control={<Radio color="primary" />}
                  label="降車地"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Typography>として設定します</Typography>
        </>
      );
    }

    if (
      dialogSetting.type === 'schedule' &&
      scheduleData.pickup &&
      scheduleData.dropoff
    ) {
      return (
        <>
          <Typography gutterBottom>
            <b>{scheduleData.pickup.name}</b>
          </Typography>
          <Box>
            <Icon>arrow_downward</Icon>
          </Box>
          <Typography paragraph>
            <b>{scheduleData.dropoff.name}</b>
          </Typography>
          <Typography>こちらの内容で配車します</Typography>
        </>
      );
    }

    return null;
  }, [dialogSetting.type, handleChangeType, scheduleData]);

  useEffect(() => {
    if (location.pathname === rootPath) {
      setCurrentNavigation(rootPath);
      return;
    }
    const currentRouteInfo = navigationRoutes
      .filter((routeInfo) => routeInfo.path.length > 0)
      .find((routeInfo) => location.pathname.includes(routeInfo.path));
    if (currentRouteInfo) {
      setCurrentNavigation(location.pathname);
    }
  }, [location.pathname, rootPath]);

  useMount(async () => {
    const setFillHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    let vw = window.innerWidth;

    window.addEventListener('resize', () => {
      if (vw === window.innerWidth) return;
      vw = window.innerWidth;
      setFillHeight();
    });

    setFillHeight();

    if (settings.schedule?.pickupDriveMode) {
      // 保存されている迎車の運転モードが存在する場合
      setScheduleData((prevState) => ({
        ...prevState,
        pickupDriveMode: settings.schedule.pickupDriveMode,
      }));
    }
  });

  useEffect(() => {
    if (settings.schedule?.vehicleId && connectedVehicles.length > 0) {
      // 保存されている車両IDが存在する場合、取得した車両一覧内に存在するか確認
      const savedVehicle = connectedVehicles.find(
        (vehicle) => vehicle.vehicle_id === settings.schedule.vehicleId,
      );
      if (savedVehicle) {
        if (scheduleData.vehicle?.vehicle_id !== savedVehicle.vehicle_id) {
          // 存在する場合stateに設定
          setScheduleData((prevState) => ({
            ...prevState,
            vehicle: savedVehicle,
          }));
        }
      } else {
        if (scheduleData.vehicle && settings.schedule.vehicleId !== '') {
          setScheduleData((prevState) => ({
            ...prevState,
            vehicle: null,
          }));
          updateSchedule('vehicleId', '');
        }
      }
    }
  }, [
    connectedVehicles,
    scheduleData,
    setScheduleData,
    settings.schedule,
    updateSchedule,
  ]);

  useEffect(() => {
    if (
      prevEnvironment &&
      prevEnvironment?.environment_id !== environment?.environment_id
    ) {
      setScheduleData((prevState) => ({
        ...prevState,
        tempSelectedPlace: null,
        vehicle: null,
        pickup: null,
        dropoff: null,
      }));
    }
  }, [environment, prevEnvironment, setScheduleData]);

  return (
    <Root>
      <ContentBox hasCreateBasicScheduleScope={hasCreateBasicScheduleScope}>
        {children}
      </ContentBox>
      <ScheduleInfo onClickRegister={handleClickScheduleRegister} />
      <Box width="100%">
        <BottomNavigation
          showLabels
          value={currentNavigation}
          onChange={handleChangeBottomNavigation}
        >
          {navigationRoutes.map((routeInfo, i) => (
            <BottomNavigationAction
              key={i}
              value={`${rootPath}${routeInfo.path}`}
              label={routeInfo.id}
              icon={routeInfo.icon}
            />
          ))}
        </BottomNavigation>
      </Box>
      <ProjectEnvironmentDialog
        open={environmentDialogOpen}
        onClose={handleCloseEnvironmentDialog}
        project={project}
        environment={environment}
      />
      <Dialog
        fullWidth
        style={{ zIndex: 2000 }}
        open={dialogSetting.open}
        onClose={handleCloseDialog}
        TransitionProps={{
          onExited: handleExitedDialog,
        }}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          {dialogContent}
          <DialogProgress open={dialogSetting.progress} />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleCloseDialog}>
            キャンセル
          </Button>
          <Button color="primary" onClick={handleClickDialogOk}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={places.length === 0}>
        <DialogContent>
          <DialogContentText>
            停車地点が存在しません
            <br />
            プロジェクトもしくは走行環境を変更してください
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClickSettingPage}>
            設定ページへ
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
};

export default Layout;

const Root = styled.div`
  display: flex;
  min-height: 100%;
  position: relative;
  flex-wrap: wrap;
  background-color: rgb(247, 249, 252);
`;

type ContentBoxProps = BoxProps & {
  hasCreateBasicScheduleScope: boolean;
};

const ContentBox = styled(
  // eslint-disable-next-line
  ({ hasCreateBasicScheduleScope, ...args }: ContentBoxProps) => (
    <Box {...args} />
  ),
)<ContentBoxProps>`
  width: 100%;
  overflow: auto;
  ${({ hasCreateBasicScheduleScope }: ContentBoxProps) => {
    if (hasCreateBasicScheduleScope) {
      return css`
        height: calc(
          100vh - ${SCHEDULE_INFO_HEIGHT}px - ${BOTTOM_NAVIGATION_HEIGHT}px
        );
        height: calc(
          var(--vh, 1vh) * 100 - ${SCHEDULE_INFO_HEIGHT}px -
            ${BOTTOM_NAVIGATION_HEIGHT}px
        );
      `;
    }

    return css`
      height: calc(100vh - ${BOTTOM_NAVIGATION_HEIGHT}px);
      height: calc(var(--vh, 1vh) * 100 - ${BOTTOM_NAVIGATION_HEIGHT}px);
    `;
  }}
`;
