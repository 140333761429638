import { useNotification } from '@/data/notification/hooks';
import { useCallback, useState } from 'react';
import { Failure, Success, type APIState, type Result } from '../types';
import type { Place } from '@/data/fms/place/types';
import { useFMSAPI } from '../hooks';
import { useHasScope } from '@/data/auth/scope/hooks';
import type { AxiosError, AxiosResponse } from 'axios';
import { SCOPES } from '@/data/auth/scope/constants';
import { projectAtom } from '@/data/auth/project/states';
import { environmentAtom } from '@/data/fms/environment/states';
import { useAtomCallback } from 'jotai/utils';

/**
 * 停車地点一覧取得API
 */
export const useGetPlacesAPI = (): {
  state: APIState;
  getPlaces: () => Promise<Place[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getHasScope = useHasScope();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
    ): Promise<Result<Place[], AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/places`,
        );
        if (!res?.data) return new Success([]);
        // 昇順ソート
        res.data.sort((a: Place, b: Place) => {
          if (a.name < b.name) return -1;
          return 1;
        });
        return new Success(res.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getPlaces = useAtomCallback(
    useCallback(
      async (get) => {
        setState('loading');
        if (!getHasScope(SCOPES.DescribePlace)) {
          setState('hasError');
          return [];
        }

        const project = get(projectAtom);
        const environment = get(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: `停車地点一覧の取得に失敗しました`,
          });
          setState('hasError');
          return [];
        }
        const res = await request(project.id, environment.environment_id);
        if (!res || res.isFailure()) {
          notifyError({
            message: `停車地点一覧の取得に失敗しました: ${
              res.value?.data?.message ?? '原因不明'
            }`,
          });
          setState('hasError');
          return [];
        }
        setState('hasValue');
        return res.value;
      },
      [notifyError, request, getHasScope],
    ),
  );

  return {
    state,
    getPlaces,
  };
};
